// Theme
@import 'theme/argon-dashboard-react';
@import '../plugins/nucleo/css/nucleo';

// Utils
@import './utils';

// Components
@import './components/navigation';
@import './components/list';
@import './components/card';
@import './components/form';
@import './components/nav';
@import './components/modal';
@import './components/accordion';
@import './components/discussion';
@import './components/tab-sheet-action';
@import './components/input';
@import './components/custom/menu-button.scss';
@import './components/communities.scss';
@import './components/exporter.scss';

// Views
@import './views/errors';
@import './views/manager/manager';
@import './views/shared/layout';


#modal-copy-calcs {
    min-width: 70%;
}