.navigation.navbar-nav {
    .nav-link {
        cursor: pointer;
        user-select: none;
    }

    .collapse, .collapsing {
        .nav-link {
            padding: .3rem;
            padding-left: 4rem;
            font-size: .8rem;

            i {
                font-size: .8rem;
            }
        }
    }
}

.weverapp-select-community {
    min-width: 250px;

    svg {
        color: black!important;

        &:hover {
            color: black!important;
        }
    }
    text {
        color: white;
    } 
}

.community-background {
    background-color: white !important;
}

.mapsLeaflet {
    z-index: 0;
}