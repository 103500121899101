.tab-sheet-action {
  color: #D73755 !important;
  background: #ffffff;
  font-weight: bold;
}

.tab-sheet-action.active {
  color: white !important;
  background-color: #00AAD7 !important;
}

.tab-sheet-action-container, .card-shadow-card-content .card-body {
  min-height: calc(100vh - 220px) !important;
}

.text-blue-wever {
  color: #00AAD7;
  font-weight: bold;
  font-size: 20px;
}

.card-img-wever {
  height: auto;
  width: auto;
  max-width: 100px;
  max-height: 200px;
  border: #aba7a7;
  margin-right: 10px;
}

.card-img-wever:hover {
  cursor: pointer;
}

.weverCardUsefulMedia {
  display: flex;
  text-align: center;
}

#usefulMediaCard {
  text-align: center;
  justify-content: center;
  border: #888787;
  border-radius: 20%;
  box-shadow: 0 auto 8px 0 #af3535;
  height: auto;
  width: auto;
  justify-content: space-between;
}

embed {
  cursor: pointer;
}

.iframe-dashboard iframe{
  height: calc(100vh - 220px) !important; 
}

.graphapp-text{
  height: 100% !important; 
}

.colHeight{
  height : fit-content !important;
}

.iframe-dashboard div.dashboardappContainer div{
  height : 100% !important;
}

.graphappContainer{
  border: none
}

.return-sheet-action{
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
  margin-bottom: -9px;
  padding-top: 62px;
  padding-bottom: 20px;
}
.iframe-dashboard iframe{
  min-height : 100% !important;
}
.iframe-dashboard div.dashboardappContainer div{
  min-height : 100% !important;
}
.iframe-dashboard div.dashboardappContainer {
  min-height : 100% !important;
}

.graphappContainer{
  border: none
}

.coreTab {
  margin-top: 55px;

  .coreTabNavLink.active {
    background-color: #eee;
  }
}