.bg-blue-light {
    background-color: rgb(207, 210, 255);
}

.details-eligibility {
    h5 {
        font-size: 1.1em;
        margin-bottom: 0.5em;
    }
    p {
        font-size: 0.9em;
        margin: 0;
    }
}

.exporter-eligibility-bloc {
    border: 1px dashed #ccc;
    padding: 15px 5px;
    min-height: 100px;
    position: relative;


    .header-bloc {
        position: absolute;
        top: -20px;
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    .export-child-eligibility {
        padding: 15px 10px;
        background-color: rgb(246, 247, 255);
        border-radius: 10px;

        .form-group {
            margin: 0;
        }
    }

    div[class*="multiValue"] {
        background-color: white;
        border: 1px solid rgb(189, 21, 21);
        border-radius: 50px;

        div {
            color: rgb(189, 21, 21);
        }
    }
}

.export-separator {
    width: 1px;
    border-left: 1px dashed black;
    height: 20px;
    margin: 10px 0;
}

.export-separator-and {
    font-weight: bold;
    font-size: 0.9em;
    word-spacing: 1.2px;

}