.modal {
    &-header {
        .modal-title {
            width: 100%;
            display: flex;
        }
    }
}


.modal-list-cals {
    max-height: 500px!important;
    overflow-y: scroll!important;

    td, th {
        text-align: center;
    }

    th, th button {
        font-size: 0.8em!important;
        text-transform: uppercase;
        font-weight: bold;
    }

    .filter-tags {
        font-size: 0.6em;
    }
}