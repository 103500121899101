.f-width { width: 100% !important; }
.f-height { height: 100% !important; }
.pointer { cursor: pointer; }
.z-index-1 { z-index: 1; }
.z-index--1 { z-index: -1; }
.z-index-0 { z-index: 0; }
.disabled { pointer-events: none; }
.absolute { position: absolute }

$darkPinkColor: #d73755;
$darkPinkColorFocus: #ac203a;
$darkPinkColor2: #b8223d;
$blueWaterColor: #0d9fdb;
$lightPinkColor: #c2c2c2;
$lightGreyColor: #EBF3FA;

.wysiwygEditorClassName.rdw-editor-main {
  background-color: #eee;
}

.full-page {
  height: 100vh;
  width: 100%;
}

.flex {
  display: flex;

  &-center {
    justify-content: center;
    align-items: center;
  }

  &-wrap {
    flex-wrap: wrap;
  }
}

.loading-layer {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, .5);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  pointer-events: none;
}

.icon {
  &RightMargin {
    &Max{
      margin-right: 1.15rem;
    }

    &Mid {
      margin-right: 1rem;
    }

    &Small{
      margin-right: 0.5rem;
    }
  }
}

.bg-{
  &darkpink {
    background-color: $darkPinkColor!important;
    color: white;
    border: none;
  }
}

.text {
  &-red {
    color: red;
  }
  &-pink {
    color: rgb(226, 168, 177);
  }
  &-violet {
    color: violet;
  }
  &-green-dark {
    color: rgb(3, 97, 3);
  }
}

.main-content, .view-layout-container {
  height: 100%;
}

.view-fade {
  width: 100%;
}

.adminapp-editorapp-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .containerViewTwo {
    margin-left: 2px;
    margin-right: 0;
  }

  .react-notification-root {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

h2 {
  font-weight: bold;
  font-size: 20px;
  object-fit: cover;
}

h3 {
  color: #00AAD7;
  font-weight: bold;
  font-size: 20px;
}

.text-pink-wever {
  color: $darkPinkColor;
}

.bg-white {
  background-color: white;
}

.btn-wever {
  border: none;
  padding: 5px 10px;
  border-radius: 10px;
  text-transform: uppercase;
}

.bg-pink-wever{
  background-color: $darkPinkColor!important;
  color: white;

  &:hover{
    background-color: $darkPinkColorFocus!important;
  }
}

.bg-wever-{
  &light {
    background-color: #fafafa!important;
  }

  &tpg-orange{
    background-color: #FD5312;
    color: white;

    &:hover, &:focus{
      background-color: #d43f04;
    }
  }
}

.d-flex > button, .d-flex > img {
  margin: auto;
}



.w-125px {
  width: 125px;
}

.w-175px {
  width: 175px;
}

div.bg-light {
  background-color: #fafafa!important;
}

.select-hide-indicator div[class*="-indicatorSeparator"] {
  display: none;
}

#root > .react-notification-root  > div[class*="notification-container-top"] {
  position: fixed;
}