.w-50px {
    width: 50px;
    text-wrap: balance!important;
}

.w-150px {
    width: 150px;
    text-wrap: balance!important;
}

.w-280px {
    width: 280px;
}

.communities-sites-table input {
    font-size: 12px;
}

.address-site {
    min-width: 300px;
    *[class*="-menu"] {
        min-width: 350px!important;
    }
}

.number-no-arrow {
    &input::-webkit-outer-spin-button,
    &input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.address-site-txt {
    color: #555;
    max-width: 200px;
    font-size: 0.9em;
}

.min-width-150 {
    min-width: 150px;
}

// FA

.displayer-sheet-actions-generic-header {
    margin-bottom: 1em;
    border-bottom: 1px solid #0d9fdb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    visibility: hidden;

    &.to-display {
        background-color: #d79d57;
        visibility: visible;
    }

    button {
        border: none;
        background-color: transparent;
        color: black;
        box-shadow: none;

        h5 {
            margin: 0;
        }

    
        &:hover, &:focus {
            background-color: transparent;
            color: #28017d;
            box-shadow: none;
        }

        &.btn-visualisation {
            background-color: #a3ebdf;
            color: black;
        }

        &.btn-validate-all-generic {
            background-color: #0d9fdb!important;
            color: white;
        }
    }
}

ul.container-fa-generic-list {
    background-color: #93daf5;
    border-radius: 10px;

    li {
        background-color: transparent;
        
        button {
            background-color: #c30937;
            color: white;
        }
    }
}
