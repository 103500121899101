#error-page {
  height: calc(100vh - 80px);

  h1 {
    font-size: 65px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .content {
    margin-top: 30px;

    h2 {
      font-size: 21px;
      font-weight: 400;
      margin: 0;
      text-transform: uppercase;
      color: #151723;
    }

    p {
      color: #999fa5;
      font-weight: 400;
    }

    a {
      display: inline-block;
      font-weight: 700;
      border-radius: 40px;
      text-decoration: none;
      color: #388dbc;
    }
  }
}

.error {
  &-access-denied, &-not-found{
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 2.5em;
    font-weight: bold;
    color: $darkPinkColor;
  }
}
