.wever-menu {
    position: relative;

    .wever-menu-button {
        background: white;
        color: black;
        border: 1px solid #ccc;

        .fa-ellipsis-v  {
            color: black;
        }
    }

    .wever-menu-button:hover {
        background-color: #eee;
    }

    // .wever-menu-content {
    //     position: absolute;
    //     min-width: 300px;
    //     z-index: 1000;
    //     background-color: white;
    //     border: 1px solid #ccc;
    //     top: -50%;
    //     left: -300px;
    //     padding: 5px;
    //     text-align: center;

    //     a {
    //         color: black;
    //         text-decoration: none;
    //         padding: 5px;
    //     }

    //     a:hover {
    //         color: blue;
    //     }
    // }
}